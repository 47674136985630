
import inThumb from '../../../assets/images/thumbs/in-thumb.jpg'
import exThumb from '../../../assets/images/thumbs/ex-thumb.jpg'

import ex0 from '../../../assets/images/fulls/6360-exterior/0.jpg'
import ex1 from '../../../assets/images/fulls/6360-exterior/1.jpg'
import ex2 from '../../../assets/images/fulls/6360-exterior/2.jpg'
import ex3 from '../../../assets/images/fulls/6360-exterior/3.jpg'
import ex4 from '../../../assets/images/fulls/6360-exterior/4.jpg'
import ex5 from '../../../assets/images/fulls/6360-exterior/5.jpg'
import ex6 from '../../../assets/images/fulls/6360-exterior/6.jpg'
import ex7 from '../../../assets/images/fulls/6360-exterior/7.jpg'
import ex8 from '../../../assets/images/fulls/6360-exterior/8.jpg'
import ex9 from '../../../assets/images/fulls/6360-exterior/9.jpg'

import in0 from '../../../assets/images/fulls/6360-interior/00.jpg'
import in1 from '../../../assets/images/fulls/6360-interior/1.jpg'
import in2 from '../../../assets/images/fulls/6360-interior/2.jpg'
import in3 from '../../../assets/images/fulls/6360-interior/3.jpg'
import in4 from '../../../assets/images/fulls/6360-interior/4.jpg'
import in5 from '../../../assets/images/fulls/6360-interior/5.jpg'
import in6 from '../../../assets/images/fulls/6360-interior/6.jpg'
import in7 from '../../../assets/images/fulls/6360-interior/7.jpg'
import in8 from '../../../assets/images/fulls/6360-interior/8.jpg'
import in9 from '../../../assets/images/fulls/6360-interior/9.jpg'
import in10 from '../../../assets/images/fulls/6360-interior/10.jpg'


export const DEFAULT_IMAGES = [
    {
      id: '0',
      images: [{source: ex1}, {source: ex2},
               {source: ex3}, {source: ex4}, {source: ex5},
               {source: ex6}, {source: ex7}, {source: ex8}],
      thumbnail: exThumb,
      caption: 'Exterior',
      description: 'The process of rebuilding a house from scratch',
    },
    {
      id: '1',
      images: [{source: in0},
          {source: in1}, {source: in2}, {source: in3},
          {source: in4}, {source: in5}, {source: in6},
          {source: in7}, {source: in8}, {source: in9}, {source: in10}],
      thumbnail: inThumb,
      caption: 'Interior',
      description: 'The interior design of the rebuilt house',
    },
    // {
    //   id: '3',
    //   source: full03,
    //   thumbnail: thumb03,
    //   caption: 'Photo 3',
    //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    // },
    // {
    //   id: '4',
    //   source: full04,
    //   thumbnail: thumb04,
    //   caption: 'Photo 4',
    //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    // },
    // {
    //   id: '5',
    //   source: full05,
    //   thumbnail: thumb05,
    //   caption: 'Photo 5',
    //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    // },
    // {
    //   id: '6',
    //   source: full06,
    //   thumbnail: thumb06,
    //   caption: 'Photo 6',
    //   description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.',
    // }
]